<template>
  <div class="uk-container uk-text-center">
    <div class="uk-text-left">
      Jeśli
      <ul class="uk-margin-remove">
        <li>chcesz się podzielić opinią</li>
        <li>masz pomysł na nowe funkcjonalności</li>
        <li>masz problem z aplikacją</li>
      </ul>
      <p>
        Proszę opisz to w kilku słowach poniżej i wyślij do mnie. Z góry bardzo
        dziękuję!
      </p>
    </div>
    <textarea
      class="uk-form uk-textarea"
      name="feedback"
      id=""
      cols="30"
      rows="5"
      placeholder="treść wiadomości"
      v-model="feedback"
    ></textarea>
    <input
      type="text"
      class="uk-margin-small-top uk-form uk-input"
      placeholder="imię i nazwisko / login FB"
      v-model="from"
    />
    <button
      class="uk-button uk-button-primary uk-margin-small uk-border-rounded"
      @click="send"
      :disabled="(from.length > 4 && feedback.length) > 4 ? false : true"
    >
      Wyślij wiadomość
    </button>
  </div>
</template>

<script lang="js">
import axios from 'axios'

export default {
  data () {
    return {
      feedback: '',
      from: ''
    }
  },
  methods: {
    send () {
      axios.post('https://hook.integromat.com/zqtk1l0v1zn7uryafs3oe7ric18s0c3z', {
        feedback: this.feedback,
        from: this.from
      })
      this.feedback = ''
      this.from = ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
