<template>
  <div>
    <div
      class="product uk-margin-small"
      v-for="product in value.products"
      :key="product.id"
    >
      <h5 class="uk-margin-remove">{{ product.name }}</h5>
      <div class="uk-grid-collapse" uk-grid>
        <div class="uk-text-muted uk-width-1-2">
          {{ parseFloat(product.quantity, 2) }} x
          {{ parseFloat(product.price).toFixed(2) }}
          <span class="uk-text-small">{{ product.unit }}</span>
        </div>
        <div class="uk-width-1-2">
          <h5 class="uk-margin-remove uk-text-right">
            {{ subTotal(product) }} zł
          </h5>
        </div>
      </div>
    </div>
    <div class="orderSummary">
      <h4 class="total">
        <span class="label">suma:</span>
        {{ total }} zł
      </h4>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProductList',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    total() {
      return (
        this.value.products
          .reduce((a, b) => a + b.quantity * b.price, 0)
          .toFixed(2) || 0
      )
    }
  },
  methods: {
    subTotal(product) {
      return parseFloat(product.quantity * product.price).toFixed(2)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './assets/theme.scss';

.orderSummary {
  @extend .uk-text-right;
  .total {
    .label {
      @extend .uk-text-muted;
      @extend .uk-text-small;
    }
  }
}
</style>
